<template>
  <v-container
    fluid
  >
    <v-img
      src="@/assets/RecycledItem.jpg"
      contain
      dark
    >
      <div
        class="display-3 font-weight-bold text-center mb-2"
      >
        {{ $t('title') }}
      </div>
      <div
        class="display-1 text-center mb-2"
      >
        {{ $t('appDescription') }}
      </div>
    </v-img>
    <v-row>
      <v-col
        cols="12"
      />
    </v-row>
    <v-row>
      <v-col
        cols="12"
        lg="12"
        md="12"
        sm="12"
      >
        <v-btn
          v-for="(n, i) in items"
          :key="`item-${i}`"
          text
          class="display-1 font-weight-bold"
          @click="onButtonClick(n.to)"
        >
          {{ n.text }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'LandingPage',
    data: function () {
      return {
        items: [
          {
            text: 'Legal',
            to: 'https://www.eastman.com/Pages/Legal_Information.aspx',
          },
          {
            text: 'Privacy',
            to: 'https://www.eastman.com/privacy/Pages/Overview.aspx',
          },
        ],
      }
    },
    methods: {
      onButtonClick: function (href) {
        window.open(href, '_blank')
      },
    },
  }
</script>
